export const metadata = {
  getBrowserInfo() {
    const ua = navigator.userAgent;
    let browser = "Unknown";
    let version = "Unknown";

    // Extract browser and version
    if (ua.includes("Chrome")) {
      browser = "Chrome";
      version = ua.match(/Chrome\/(\d+\.\d+)/)?.[1];
    } else if (ua.includes("Firefox")) {
      browser = "Firefox";
      version = ua.match(/Firefox\/(\d+\.\d+)/)?.[1];
    } else if (ua.includes("Safari") && !ua.includes("Chrome")) {
      browser = "Safari";
      version = ua.match(/Version\/(\d+\.\d+)/)?.[1];
    } else if (ua.includes("Edge")) {
      browser = "Edge";
      version = ua.match(/Edge\/(\d+\.\d+)/)?.[1];
    }

    return `${browser} ${version}`;
  },

  getPlatformInfo() {
    const ua = navigator.userAgent;
    let platform = "Unknown";

    if (ua.includes("Windows")) {
      platform = "Windows";
    } else if (ua.includes("Mac OS")) {
      platform = "MacOS";
    } else if (ua.includes("Linux")) {
      platform = "Linux";
    } else if (ua.includes("iOS")) {
      platform = "iOS";
    } else if (ua.includes("Android")) {
      platform = "Android";
    }

    return platform;
  },

  getCurrentUrl() {
    return window.location.href;
  },

  getMetadata() {
    return {
      browser: this.getBrowserInfo(),
      platform: this.getPlatformInfo(),
      url: this.getCurrentUrl(),
      timestamp: new Date().toISOString(),
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      language: navigator.language,
    };
  },
};

export default metadata;
