export const screenshot = {
  async capture() {
    try {
      const html2canvas = await window.html2canvas;
      if (!html2canvas) {
        throw new Error("html2canvas not loaded");
      }

      const canvas = await html2canvas(document.body, {
        logging: false,
        useCORS: true,
        scale: window.devicePixelRatio || 1,
        ignoreElements: (element) => {
          return element.classList.contains("happy-panda-widget");
        },
        backgroundColor: "#ffffff",
      });

      return canvas.toDataURL("image/jpeg", 0.8);
    } catch (error) {
      console.error("Screenshot capture failed:", error);
      return null;
    }
  },
};

export default screenshot;
