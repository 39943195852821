import html2canvas from "html2canvas";
import Widget from "./components/Widget/Widget";
import "./styles/main.css";
import "./styles/theme.css";
import { api, config, logger } from "./utils";

// Make html2canvas available globally
window.html2canvas = html2canvas;

// Create the widget object first
const happypanda = {
  widgetInstance: null,

  async init(userConfig) {
    try {
      if (!userConfig.projectId) {
        throw new Error("projectId is required");
      }

      if (!userConfig.token) {
        throw new Error("widget token is required");
      }

      // Fetch configuration from API with token
      const apiConfig = await api.fetchWidgetConfig(
        userConfig.projectId,
        userConfig.token
      );

      // Store token for future API calls
      const mergedConfig = config.mergeConfig({
        ...apiConfig,
        ...userConfig,
        // Preserve API texts but allow user overrides
        texts: {
          ...(apiConfig.texts || {}),
          ...(userConfig.texts || {}),
        },
        // Ensure token is available for API calls
        _token: userConfig.token,
        // Ensure hideDefaultButton from API config is preserved
        hideDefaultButton: apiConfig.hideDefaultButton || false,
      });

      // Validate final configuration
      const validatedConfig = config.validateConfig(mergedConfig);

      // Render widget with config including token
      this.widgetInstance = await Widget.render(validatedConfig);
      document.body.appendChild(this.widgetInstance);

      // Return the instance for method chaining
      return this;
    } catch (error) {
      logger.error("Widget initialization failed:", error);
      throw error;
    }
  },

  // Public control methods
  open() {
    if (this.widgetInstance) {
      Widget.open();
    } else {
      logger.error("Widget not initialized");
    }
  },

  close() {
    if (this.widgetInstance) {
      Widget.close();
    } else {
      logger.error("Widget not initialized");
    }
  },

  toggle() {
    if (this.widgetInstance) {
      Widget.toggle();
    } else {
      logger.error("Widget not initialized");
    }
  },

  destroy() {
    if (this.widgetInstance) {
      Widget.destroy();
      this.widgetInstance = null;
    } else {
      logger.error("Widget not initialized");
    }
  },
};

// Auto-initialization function
const initFromScript = (script) => {
  const projectId = script.getAttribute("data-project-id");
  const token = script.getAttribute("data-token");

  if (projectId && token) {
    happypanda.init({ projectId, token });
  } else {
    console.debug("Missing required attributes:", { projectId, token });
  }
};

// Immediately execute initialization code

// Function to find and initialize from script tag
const findAndInitialize = () => {
  // Look for any script that includes our widget name
  const script =
    document.currentScript ||
    document.querySelector('script[src*="/happypanda.js"]');

  if (!script) {
    const altScript = document.querySelector("script[data-project-id]");
    if (altScript) {
      initFromScript(altScript);
      return;
    }
    return;
  }

  initFromScript(script);
};

// Run initialization when DOM is ready
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", findAndInitialize);
} else {
  findAndInitialize();
}

// Export for manual initialization
export default happypanda;
