export const logger = {
  prefix: "[HappyPanda]",

  info(...args) {
    console.info(this.prefix, ...args);
  },

  error(...args) {
    console.error(this.prefix, ...args);
  },

  warn(...args) {
    console.warn(this.prefix, ...args);
  },

  debug(...args) {
    if (process.env.NODE_ENV === "development") {
      console.debug(this.prefix, ...args);
    }
  },
};

export default logger;
