import { api, dom, logger, metadata, validation } from "../../utils";
import CardContent from "../CardContent/CardContent";
import CardFooter from "../CardFooter/CardFooter";
import CardHeader from "../CardHeader/CardHeader";
import "./Card.css";

export const Card = {
  async render(config, { onReset }) {
    const card = dom.createElement("div", {
      className: `happy-panda-card ${config.theme}`,
      attributes: {
        "data-testid": "feedback-card",
        "data-position": config.buttonPosition,
      },
    });

    // Store config, state and callbacks
    this.config = config;
    this.onReset = onReset;
    this.state = {
      rating: null,
      comment: "",
      screenshot: null,
    };

    // Store card element reference and component references
    this.cardElement = card;
    this.components = {};

    // Initial render
    await this.renderComponents();

    return card;
  },

  async renderComponents() {
    if (!this.cardElement) return;

    // Clear existing content
    this.cardElement.innerHTML = "";

    try {
      // Render header
      this.components.header = CardHeader.render(this.config, {
        onClose: this.onReset,
      });
      this.cardElement.appendChild(this.components.header);

      // Render content (await since it's async)
      this.components.content = await CardContent.render(
        this.config,
        this.handleStateChange.bind(this)
      );
      this.cardElement.appendChild(this.components.content);

      // Render footer
      this.components.footer = await CardFooter.render(
        this.config,
        this.handleSubmit.bind(this)
      );
      this.cardElement.appendChild(this.components.footer);
    } catch (error) {
      logger.error("Failed to render components:", error);
      throw error;
    }
  },

  handleStateChange(update) {
    this.state = { ...this.state, ...update };
    // Clear validation errors when user makes changes
    this.clearValidationErrors();
  },

  async resetState() {
    // Reset internal state
    this.state = {
      rating: null,
      comment: "",
      screenshot: null,
    };

    try {
      // Re-render the entire content
      const newContent = await CardContent.render(
        this.config,
        this.handleStateChange.bind(this)
      );

      // Replace old content with new
      const oldContent = this.cardElement.querySelector(
        ".happy-panda-card-content"
      );
      if (oldContent) {
        oldContent.replaceWith(newContent);
        this.components.content = newContent;
      }

      // Reset footer
      if (CardFooter.reset) {
        await CardFooter.reset(this.components.footer, this.config);
      }
    } catch (error) {
      logger.error("Failed to reset components:", error);
      throw error;
    }
  },

  async handleSubmit() {
    try {
      const isLocalhost = window.location.hostname === "localhost";

      if (isLocalhost) {
        alert("HappyPanda: Skipping submission on localhost");
        console.debug("Localhost debugger values:", this.state);
        return { isValid: true, errors: {} };
      }

      const { isValid, errors } = validation.validateFeedback({
        comment: this.state.comment,
        rating: this.state.rating,
      });

      if (!isValid) {
        logger.error("Invalid feedback:", errors);
        this.showValidationErrors(errors);
        // Reset submit button state when validation fails
        if (CardFooter.reset) {
          await CardFooter.reset(this.components.footer, this.config);
        }
        return { isValid, errors };
      }

      const feedbackData = {
        comment: this.state.comment,
        sentiment: this.getSentimentFromRating(this.state.rating),
        userMetadata: metadata.getMetadata(),
        screenshotUrl: this.state.screenshot,
      };

      await api.submitFeedback(
        this.config.projectId,
        feedbackData,
        this.config._token
      );

      this.showThankYouMessage();
      setTimeout(() => {
        this.onReset();
      }, 2000);
      return { isValid, errors };
    } catch (error) {
      logger.error("Failed to submit feedback:", error);
      this.showValidationErrors({
        general: "Failed to submit feedback. Please try again.",
      });
      throw error;
    }
  },

  getSentimentFromRating(rating) {
    if (!rating) return "neutral";
    if (rating >= 4) return "positive";
    if (rating <= 2) return "negative";
    return "neutral";
  },

  showThankYouMessage() {
    const content = this.cardElement.querySelector(".happy-panda-card-content");
    if (content) {
      content.innerHTML = `
        <div class="happy-panda-thank-you">
          ${this.config.texts.thankYouMessage}
        </div>
      `;
    }
  },

  showValidationErrors(errors) {
    const content = this.cardElement.querySelector(".happy-panda-card-content");
    if (!content) return;

    // Clear any existing error messages
    this.clearValidationErrors();

    // Add error messages
    Object.entries(errors).forEach(([field, message]) => {
      // Add error message
      const errorDiv = dom.createElement("div", {
        className: "happy-panda-error-message",
      });
      errorDiv.textContent = message;

      // Add error styling to related field
      if (field === "comment") {
        const textarea = content.querySelector(".happy-panda-textarea");
        if (textarea) {
          textarea.classList.add("error");
          textarea.parentNode.insertBefore(errorDiv, textarea.nextSibling);
        }
      } else if (field === "rating") {
        const ratingSection = content.querySelector(
          ".happy-panda-rating-section"
        );
        if (ratingSection) {
          ratingSection.classList.add("error");
          ratingSection.appendChild(errorDiv);
        }
      } else {
        // General errors
        content.insertBefore(errorDiv, content.firstChild);
      }
    });
  },

  clearValidationErrors() {
    const content = this.cardElement.querySelector(".happy-panda-card-content");
    if (!content) return;

    // Remove error messages
    const errorMessages = content.querySelectorAll(
      ".happy-panda-error-message"
    );
    errorMessages.forEach((msg) => msg.remove());

    // Remove error styling
    const errorElements = content.querySelectorAll(".error");
    errorElements.forEach((el) => el.classList.remove("error"));
  },
};

export default Card;
