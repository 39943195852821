const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/api"
    : "https://api.happypanda.ai/api";

export const api = {
  async fetchWidgetConfig(projectId, token) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/widget/projects/${projectId}/config`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          mode: "cors",
          credentials: "omit",
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch config");
      }
      return response.json();
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  async getScreenshotUploadUrl(projectId, token) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/widget/projects/${projectId}/screenshot-upload-url`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          mode: "cors",
          credentials: "omit",
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to get upload URL");
      }

      return response.json();
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  async uploadScreenshotToS3(uploadUrl, screenshotBlob) {
    try {
      const response = await fetch(uploadUrl, {
        method: "PUT",
        body: screenshotBlob,
        headers: {
          "Content-Type": "image/jpeg",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to upload screenshot");
      }

      return true;
    } catch (error) {
      console.error("Upload Error:", error);
      throw error;
    }
  },

  async submitFeedback(projectId, feedback, token) {
    try {
      let screenshotUrl;

      // Handle screenshot upload if present
      if (feedback.screenshotUrl) {
        // Convert base64 to blob
        const base64Data = feedback.screenshotUrl.split(",")[1];
        const blob = await fetch(`data:image/jpeg;base64,${base64Data}`).then(
          (res) => res.blob()
        );

        // Get presigned URL
        const { uploadUrl, screenshotUrl: finalUrl } =
          await this.getScreenshotUploadUrl(projectId, token);

        // Upload to S3
        await this.uploadScreenshotToS3(uploadUrl, blob);
        screenshotUrl = finalUrl;
      }

      // Submit feedback with screenshot URL
      const response = await fetch(
        `${API_BASE_URL}/widget/projects/${projectId}/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          body: JSON.stringify({
            comment: feedback.comment,
            rating: feedback.rating,
            metadata: feedback.userMetadata,
            screenshotUrl,
            categories: feedback.categories || [],
            sentiment: feedback.sentiment || "neutral",
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit feedback");
      }

      return response.json();
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },
};

export default api;
