export const validation = {
  isValidProjectId(projectId) {
    return typeof projectId === "string" && projectId.startsWith("proj_");
  },

  isValidComment(comment) {
    return typeof comment === "string" && comment.length > 0;
  },

  isValidSentiment(sentiment) {
    return ["positive", "neutral", "negative"].includes(sentiment);
  },

  isValidCategories(categories) {
    return (
      Array.isArray(categories) &&
      categories.every((category) => typeof category === "string")
    );
  },

  validateFeedback(feedback) {
    const errors = {};

    if (!feedback.comment) {
      errors.comment = "Comment is required";
    }

    if (feedback.sentiment && !this.isValidSentiment(feedback.sentiment)) {
      errors.sentiment = "Invalid sentiment value";
    }

    if (feedback.categories && !this.isValidCategories(feedback.categories)) {
      errors.categories = "Invalid categories format";
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors,
    };
  },
};

export default validation;
