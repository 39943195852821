import { config, dom, logger } from "../../utils";
import Card from "../Card/Card";
import "./Widget.css";

export const Widget = {
  async render(userConfig) {
    try {
      // Store config
      this.config = config.validateConfig(config.mergeConfig(userConfig));
      this.isOpen = false;

      // Create main container
      const widget = dom.createElement("div", {
        className: "happy-panda-widget",
        attributes: {
          "data-theme": this.config.theme,
          "data-position": this.config.buttonPosition,
        },
      });

      // Create and append toggle button if enabled
      if (this.config.buttonEnabled) {
        this.toggleButton = this.createToggleButton();
        widget.appendChild(this.toggleButton);
      }

      // Create and append card (initially hidden)
      this.card = await Card.render(this.config, {
        onReset: () => this.resetWidget(),
      });
      widget.appendChild(this.card);
      dom.hideElement(this.card);

      // Store widget reference
      this.widget = widget;

      // Attach event listeners
      this.attachEventListeners(widget, this.toggleButton, this.card);

      return widget;
    } catch (error) {
      logger.error("Failed to render widget:", error);
      throw error;
    }
  },

  async resetWidget() {
    try {
      // Reset card state first
      await Card.resetState();

      // Then handle widget-level reset
      this.isOpen = false;
      this.animateCard(this.card, false);
    } catch (error) {
      logger.error("Failed to reset widget:", error);
      throw error;
    }
  },

  // Public methods to control the widget
  open() {
    if (!this.isOpen) {
      this.isOpen = true;
      this.animateCard(this.card, true);
    }
  },

  close() {
    if (this.isOpen) {
      this.isOpen = false;
      this.animateCard(this.card, false);
    }
  },

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  },

  getInitialCardContent() {
    return `
        <label class="happy-panda-feedback-label">
          ${this.config.texts.ratingTitle}
        </label>
        <div class="happy-panda-rating-buttons">
          ${Array(5)
            .fill()
            .map(
              (_, i) => `
            <button class="happy-panda-rating-button" data-rating="${i + 1}">★</button>
          `
            )
            .join("")}
        </div>
        <textarea 
          class="happy-panda-textarea ${this.config.theme}"
          placeholder="${this.config.texts.placeholder}"
        ></textarea>
        ${
          this.config.screenshotsEnabled
            ? `
          <button class="happy-panda-screenshot-button">
            📷 Include screenshot
          </button>
        `
            : ""
        }
      `;
  },

  createToggleButton() {
    const isSide = ["left", "right"].includes(this.config.buttonPosition);

    const button = dom.createElement("button", {
      className: `happy-panda-widget-toggle ${this.config.theme} ${this.config.buttonStyle} ${this.config.buttonPosition}`,
      attributes: {
        "aria-label": `${this.config.texts.buttonText} - Open feedback form`,
        type: "button",
      },
      innerHTML: `
        <div class="happy-panda-button-content ${isSide ? "vertical" : ""}">
          <svg class="happy-panda-icon ${isSide ? "rotated" : ""}" viewBox="0 0 24 24" aria-hidden="true">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 20l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c3.255 2.777 3.695 7.266 1.029 10.501c-2.666 3.235 -7.615 4.215 -11.574 2.293l-4.7 1" />
          </svg>
          <span class="happy-panda-button-text ${isSide ? "vertical-text" : ""}">${this.config.texts.buttonText}</span>
        </div>
      `,
    });

    return button;
  },

  animateCard(card, isEntering) {
    try {
      const baseClass = isEntering
        ? "happy-panda-card-enter"
        : "happy-panda-card-exit";

      // Toggle button visibility based on card state
      if (isEntering) {
        dom.showElement(card);
        if (this.toggleButton) {
          dom.hideElement(this.toggleButton);
        }
      } else {
        if (this.toggleButton) {
          dom.showElement(this.toggleButton);
        }
      }

      // Start animation sequence
      requestAnimationFrame(() => {
        // Add initial class
        card.classList.add(baseClass);

        // Force reflow to ensure initial state is applied
        card.offsetHeight;

        // Add active class to trigger transition
        card.classList.add(`${baseClass}-active`);

        // Clean up after animation completes
        card.addEventListener(
          "transitionend",
          () => {
            // Hide the card if exiting
            if (!isEntering) {
              dom.hideElement(card);
            }
            // Remove animation classes
            card.classList.remove(baseClass, `${baseClass}-active`);
          },
          { once: true }
        );
      });
    } catch (error) {
      logger.error("Failed to animate card:", error);
      // Fallback to immediate show/hide
      if (isEntering) {
        dom.showElement(card);
        if (this.toggleButton) {
          dom.hideElement(this.toggleButton);
        }
      } else {
        dom.hideElement(card);
        if (this.toggleButton) {
          dom.showElement(this.toggleButton);
        }
      }
    }
  },

  attachEventListeners(widget, toggleButton, card) {
    // Toggle button click (if button exists)
    if (toggleButton) {
      toggleButton.addEventListener("click", () => {
        this.toggle();
      });
    }

    // Close on escape key
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape" && this.isOpen) {
        this.close();
      }
    });

    // Close on click outside
    document.addEventListener("click", (e) => {
      // Don't close if clicking inside the widget
      if (this.widget && this.widget.contains(e.target)) {
        return;
      }

      // Don't close if clicking the trigger element
      const triggerElement = e.target.closest("[data-happypanda-trigger]");
      if (triggerElement) {
        return;
      }

      // Close if widget is open and click is outside
      if (this.isOpen) {
        this.close();
      }
    });
  },

  destroy() {
    try {
      if (this.widget) {
        if (this.isOpen) {
          this.close();
        }
        this.widget.remove();
        this.widget = null;
        this.card = null;
        this.toggleButton = null;
      }
    } catch (error) {
      logger.error("Failed to destroy widget:", error);
    }
  },
};

export default Widget;
