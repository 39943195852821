import { dom, logger } from "../../utils";
import "./CardFooter.css";

export const CardFooter = {
  render(config, onSubmit) {
    try {
      this.config = config;

      const footer = dom.createElement("div", {
        className: "happy-panda-card-footer",
      });

      footer.innerHTML = `
        <div class="happy-panda-footer-content">
          <a href="https://happypanda.ai" target="_blank" rel="noopener noreferrer" class="happy-panda-powered-by ${config.theme}">
            powered by HappyPanda
          </a>
          <button class="happy-panda-submit-button ${config.theme}">
            ${config.texts.submitButton}
          </button>
        </div>
      `;

      this.attachSubmitHandler(footer, config, onSubmit);
      return footer;
    } catch (error) {
      logger.error("Failed to render card footer:", error);
      throw error;
    }
  },

  attachSubmitHandler(footer, config, onSubmit) {
    const submitButton = footer.querySelector(".happy-panda-submit-button");

    submitButton.addEventListener("click", async () => {
      try {
        submitButton.disabled = true;
        this.updateButtonState(submitButton, "sending");

        const { isValid, errors } = await onSubmit();
        if (!isValid) {
          this.updateButtonState(submitButton, "error");
          // Reset button after delay
          setTimeout(() => {
            submitButton.disabled = false;
            this.updateButtonState(
              submitButton,
              "default",
              config.texts.submitButton
            );
          }, 3000);
        } else {
          this.updateButtonState(submitButton, "success");
        }
      } catch (error) {
        logger.error("Submit failed:", error);
        this.updateButtonState(submitButton, "error");

        // Reset button after delay
        setTimeout(() => {
          submitButton.disabled = false;
          this.updateButtonState(
            submitButton,
            "default",
            config.texts.submitButton
          );
        }, 3000);
      }
    });
  },

  updateButtonState(button, state, text) {
    const states = {
      default: {
        text: text,
        icon: "",
        class: "",
      },
      sending: {
        text: "Sending...",
        icon: `
          <svg class="happy-panda-spinner" viewBox="0 0 24 24">
            <circle class="spinner-circle" cx="12" cy="12" r="10" />
          </svg>
        `,
        class: "sending",
      },
      success: {
        text: "Sent!",
        icon: `
          <svg class="happy-panda-check-icon" viewBox="0 0 24 24">
            <path d="M20 6L9 17l-5-5"/>
          </svg>
        `,
        class: "success",
      },
      error: {
        text: "Failed to send",
        icon: `
          <svg class="happy-panda-error-icon" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"/>
          </svg>
        `,
        class: "error",
      },
    };

    const currentState = states[state];
    button.className = `happy-panda-submit-button ${currentState.class}`;
    button.innerHTML = `
      ${currentState.icon}
      <span>${currentState.text}</span>
    `;
  },

  reset(footerElement, config) {
    if (!footerElement) return;

    const submitButton = footerElement.querySelector(
      ".happy-panda-submit-button"
    );
    if (submitButton) {
      submitButton.disabled = false;
      this.updateButtonState(
        submitButton,
        "default",
        config.texts.submitButton
      );
    }
  },
};

export default CardFooter;
