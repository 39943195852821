export const dom = {
  createElement(tag, options = {}) {
    const element = document.createElement(tag);

    if (options.className) {
      element.className = options.className;
    }

    if (options.attributes) {
      Object.entries(options.attributes).forEach(([key, value]) => {
        element.setAttribute(key, value);
      });
    }

    if (options.innerHTML) {
      element.innerHTML = options.innerHTML;
    }

    return element;
  },

  removeElement(selector) {
    const element = document.querySelector(selector);
    if (element) {
      element.remove();
    }
  },

  addClass(element, className) {
    element.classList.add(className);
  },

  removeClass(element, className) {
    element.classList.remove(className);
  },

  toggleClass(element, className) {
    element.classList.toggle(className);
  },

  hideElement(element) {
    if (element instanceof Element) {
      element.style.display = "none";
      element.setAttribute("aria-hidden", "true");
    } else if (typeof element === "string") {
      const el = document.querySelector(element);
      if (el) {
        el.style.display = "none";
        el.setAttribute("aria-hidden", "true");
      }
    }
  },

  showElement(element) {
    if (element instanceof Element) {
      element.style.display = "";
      element.removeAttribute("aria-hidden");
    } else if (typeof element === "string") {
      const el = document.querySelector(element);
      if (el) {
        el.style.display = "";
        el.removeAttribute("aria-hidden");
      }
    }
  },

  // Optional: Add a toggle visibility method for convenience
  toggleElement(element, force) {
    if (element instanceof Element) {
      const isHidden = element.style.display === "none";
      if (force !== undefined) {
        force ? this.showElement(element) : this.hideElement(element);
      } else {
        isHidden ? this.showElement(element) : this.hideElement(element);
      }
    } else if (typeof element === "string") {
      const el = document.querySelector(element);
      if (el) {
        this.toggleElement(el, force);
      }
    }
  },
};

export default dom;
