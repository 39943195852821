export const defaultConfig = {
  theme: "light",
  feedbackTypes: ["general"],
  screenshotsEnabled: true,
  buttonPosition: "bottom-right",
  buttonStyle: "default",
  texts: {
    placeholder: "Tell us what you think...",
    submitButton: "Send Feedback",
    thankYouMessage: "Thank you for your feedback!",
    widgetTitle: "Feedback",
    ratingTitle: "How was your experience?",
    feedbackTitle: "Tell us more",
    buttonText: "Feedback",
  },
};

export const config = {
  mergeConfig(userConfig) {
    return {
      ...defaultConfig,
      ...userConfig,
      texts: {
        ...defaultConfig.texts,
        ...(userConfig.texts || {}),
      },
    };
  },

  validateConfig(config) {
    // Validate theme
    if (config.theme && !["light", "dark"].includes(config.theme)) {
      throw new Error("Invalid theme value");
    }

    // Validate feedbackTypes
    if (config.feedbackTypes && !Array.isArray(config.feedbackTypes)) {
      throw new Error("feedbackTypes must be an array");
    }

    // Validate buttonPosition
    if (
      config.buttonPosition &&
      !["bottom-right", "bottom-left", "right", "left"].includes(
        config.buttonPosition
      )
    ) {
      throw new Error("Invalid buttonPosition value");
    }

    // Validate buttonStyle
    if (
      config.buttonStyle &&
      !["default", "minimal", "floating"].includes(config.buttonStyle)
    ) {
      throw new Error("Invalid buttonStyle value");
    }

    return config;
  },
};

export default config;
