import { dom, logger, screenshot } from "../../utils";
import "./CardContent.css";

export const CardContent = {
  async render(config, onStateChange) {
    try {
      const content = dom.createElement("div", {
        className: "happy-panda-card-content",
      });

      const sections = [];

      // Add rating section if enabled
      if (config.feedbackTypes.includes("rating")) {
        sections.push(`
          <div class="happy-panda-rating-section">
            <label class="happy-panda-feedback-label">
              ${config.texts.ratingTitle}
            </label>
            <div class="happy-panda-rating-buttons">
              ${this.renderRatingButtons(config)}
            </div>
          </div>
        `);
      }

      // Add feedback section if enabled
      if (config.feedbackTypes.includes("general")) {
        sections.push(`
          <div class="happy-panda-feedback-section">
            <label class="happy-panda-feedback-label">
              ${config.texts.feedbackTitle}
            </label>
            <textarea 
              class="happy-panda-textarea ${config.theme}"
              placeholder="${config.texts.placeholder}"
            ></textarea>
          </div>
        `);
      }

      // Add screenshot button if enabled
      if (config.screenshotsEnabled) {
        sections.push(this.renderScreenshotButton(config));
      }

      content.innerHTML = sections.join("");

      // Attach event handlers
      if (config.feedbackTypes.includes("rating")) {
        this.attachRatingHandlers(content, onStateChange);
      }
      if (config.feedbackTypes.includes("general")) {
        this.attachTextareaHandler(content, onStateChange);
      }
      if (config.screenshotsEnabled) {
        this.attachScreenshotHandler(content, onStateChange);
      }

      return content;
    } catch (error) {
      logger.error("Failed to render card content:", error);
      throw error;
    }
  },

  renderRatingButtons(config) {
    return Array(5)
      .fill()
      .map(
        (_, i) => `
          <button class="happy-panda-rating-button ${config.theme}" data-rating="${i + 1}">
            <svg class="happy-panda-star-icon" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"/>
            </svg>
          </button>
        `
      )
      .join("");
  },

  renderScreenshotButton(config) {
    return `
      <button class="happy-panda-screenshot-button ${config.theme}">
        <svg class="happy-panda-camera-icon" viewBox="0 0 24 24">
          <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"/>
          <circle cx="12" cy="13" r="4"/>
        </svg>
        Include screenshot
      </button>
    `;
  },

  attachRatingHandlers(content, onStateChange) {
    const buttons = content.querySelectorAll(".happy-panda-rating-button");
    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        buttons.forEach((b) => b.classList.remove("active"));
        button.classList.add("active");
        onStateChange({ rating: parseInt(button.dataset.rating) });
      });
    });
  },

  attachTextareaHandler(content, onStateChange) {
    const textarea = content.querySelector(".happy-panda-textarea");
    textarea.addEventListener("input", (e) => {
      onStateChange({ comment: e.target.value });
    });
  },

  async attachScreenshotHandler(content, onStateChange) {
    const button = content.querySelector(".happy-panda-screenshot-button");
    button.addEventListener("click", async () => {
      try {
        button.disabled = true;
        button.innerHTML = `
          <svg class="happy-panda-camera-icon" viewBox="0 0 24 24">
            <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"/>
            <circle cx="12" cy="13" r="4"/>
          </svg>
          Capturing...
        `;

        const screenshotUrl = await screenshot.capture();
        if (screenshotUrl) {
          onStateChange({ screenshot: screenshotUrl });
          button.innerHTML = `
            <svg class="happy-panda-check-icon" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"/>
            </svg>
            Screenshot captured
          `;
          button.classList.add("success");
        }
      } catch (error) {
        logger.error("Screenshot capture failed:", error);
        button.innerHTML = `
          <svg class="happy-panda-error-icon" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"/>
          </svg>
          Failed to capture
        `;
        button.classList.add("error");
      } finally {
        button.disabled = false;
      }
    });
  },

  reset(contentElement) {
    if (!contentElement) return;

    // Reset rating buttons
    const ratingButtons = contentElement.querySelectorAll(
      ".happy-panda-rating-button"
    );
    ratingButtons.forEach((button) => button.classList.remove("active"));

    // Reset textarea
    const textarea = contentElement.querySelector(".happy-panda-textarea");
    if (textarea) {
      textarea.value = "";
    }

    // Reset screenshot button
    const screenshotButton = contentElement.querySelector(
      ".happy-panda-screenshot-button"
    );
    if (screenshotButton) {
      screenshotButton.innerHTML = `
        <svg class="happy-panda-camera-icon" viewBox="0 0 24 24">
          <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"/>
          <circle cx="12" cy="13" r="4"/>
        </svg>
        Include screenshot
      `;
      screenshotButton.classList.remove("success", "error");
      screenshotButton.disabled = false;
    }
  },
};

export default CardContent;
