import { dom, logger } from "../../utils";
import "./CardHeader.css";

export const CardHeader = {
  render(config, { onClose }) {
    try {
      const header = dom.createElement("div", {
        className: `happy-panda-card-header ${config.theme}`,
      });

      header.innerHTML = `
        <div class="happy-panda-header-content">
          <div class="happy-panda-title-wrapper">
            <svg class="happy-panda-feedback-icon" viewBox="0 0 24 24">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M3 20l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c3.255 2.777 3.695 7.266 1.029 10.501c-2.666 3.235 -7.615 4.215 -11.574 2.293l-4.7 1" />
            </svg>
            <h3 class="happy-panda-title">${config.texts.widgetTitle}</h3>
          </div>
          <button 
            class="happy-panda-close-button" 
            aria-label="Close feedback form"
          >
            <svg class="happy-panda-close-icon" viewBox="0 0 24 24">
              <path d="M18 6L6 18M6 6l12 12"/>
            </svg>
          </button>
        </div>
      `;

      this.attachCloseHandler(header, onClose);
      return header;
    } catch (error) {
      logger.error("Failed to render card header:", error);
      throw error;
    }
  },

  attachCloseHandler(header, onClose) {
    const closeButton = header.querySelector(".happy-panda-close-button");
    closeButton.addEventListener("click", onClose);
  },
};

export default CardHeader;
